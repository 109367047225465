import { render, staticRenderFns } from "./newScriptManagementOne.vue?vue&type=template&id=3d2d80bd&scoped=true&"
import script from "./newScriptManagementOne.vue?vue&type=script&lang=js&"
export * from "./newScriptManagementOne.vue?vue&type=script&lang=js&"
import style0 from "./newScriptManagementOne.vue?vue&type=style&index=0&id=3d2d80bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d2d80bd",
  null
  
)

export default component.exports